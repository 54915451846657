import { computed } from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useFiltersStore } from '@/stores/filters'
dayjs.extend(utc)

const datesDifference = computed(() => {
  const store = useFiltersStore()

  const startDate = dayjs.unix(store.multiCharts.startDateUnix).utc()
  const endDate = dayjs.unix(store.multiCharts.endDateUnix).utc()

  return endDate.diff(startDate, 'hours')
})

const isDaterangeLoading = computed(() => {
  const store = useFiltersStore()
  return store.multiCharts.loadingStatus === 'daterange-loading'
})

export {
  datesDifference,
  isDaterangeLoading
}
