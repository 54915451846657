import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { pinia } from './stores'
import InlineSvg from 'vue-inline-svg'
// import socket from '@/plugins/socket'
import VueToast from 'vue-toast-notification'
import mitt from 'mitt'
import 'vue-toast-notification/dist/theme-sugar.css'
import '@/assets/styles/index.css'

const app = createApp(App)
const emitter = mitt()

app.use(VueToast)
app.use(pinia)
app.use(router)
// app.use(socket)

app.config.globalProperties.emitter = emitter
app.provide('emitter', app.config.globalProperties.emitter)

app.component('InlineSvg', InlineSvg)

app.mount('#app')

export default app
