<template>
  <div
    :class="[multiCharts.singleColumn ? 'max-w-screen-sm' : 'max-w-screen-2xl']"
    class="mx-auto my-8 px-5">
    <h1 class="mb-2">Multi Charts</h1>

    <p class="mb-4">Price Charts</p>

    <MultiChartsFilters class="mb-4"/>

    <AlertMessage
      v-if="error"
      type="error"
      class="max-w-sm"
      :heading="error"/>

    <template v-else>
      <div
        :class="[multiCharts.singleColumn ? 'grid-cols-1' : 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4']"
        class="grid gap-2">
        <template v-if="multiCharts.loadingStatus === 'initial-loading'">
          <div
            v-for="item in 20"
            :key="item"
            class="skeleton-loading h-[184px] rounded-lg"/>
        </template>

        <template v-else>
          <MultiChartsItem
            v-for="item in items"
            :key="item.ticker"
            :resize-anchor="resizeAnchor"
            :item="item"
            @star="starCoin"/>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { get } from '@/helpers/fetch'
import MultiChartsItem from '@/components/MultiCharts/MultiChartsItem.vue'
import AlertMessage from '@/components/Functional/AlertMessage.vue'
import MultiChartsFilters from '@/components/MultiCharts/MultiChartsFilters.vue'
import { mapWritableState } from 'pinia'
import { useFiltersStore } from '@/stores/filters'
import dayjs from 'dayjs'

export default {
  name: 'MultiChartsView',
  components: {
    MultiChartsItem,
    AlertMessage,
    MultiChartsFilters
  },
  data () {
    return {
      resizeAnchor: null,
      error: null,
      items: []
    }
  },
  computed: {
    ...mapWritableState(useFiltersStore, [
      'multiCharts'
    ]),
    filteredItems () {
      return this.items.filter(item => item.candles.length)
    }
  },
  beforeUnmount () {
    this.emitter.all.clear()
  },
  async mounted () {
    this.emitter.on('multi-charts-timeframe', this.timeframeChanged)
    this.emitter.on('multi-charts-daterange', this.daterangeChanged)
    this.emitter.on('multi-charts-single-column', this.singleColumnChanged)

    try {
      await this.getPrices('initial')
    } catch (error) {
      this.error = error.message
    } finally {
      this.multiCharts.loadingStatus = 'loaded'
    }

    window.addEventListener('resize', this.resizeHandler)
  },
  unmounted () {
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    resizeHandler (event) {
      this.resizeAnchor = event.timeStamp
    },
    async getPrices (loadingType) {
      if (loadingType) {
        this.multiCharts.loadingStatus = `${loadingType}-loading`
      }
      else {
        this.multiCharts.loadingStatus = 'loading'
      }

      // console.log(this.multiCharts.startDateUnix)
      // console.log(this.multiCharts.endDateUnix)

      const date1 = dayjs.unix(this.multiCharts.startDateUnix)
      const date2 = dayjs.unix(this.multiCharts.endDateUnix)
      console.log(date2.diff(date1, 'hours'))

      console.log({
        start_time: this.multiCharts.startDateUnix,
        finish_time: this.multiCharts.endDateUnix,
        fields: 'close',
        timeframe: this.multiCharts.timeframe
      })

      const { items } = await get('/data/multi-charts', {
        start_time: this.multiCharts.startDateUnix,
        finish_time: this.multiCharts.endDateUnix,
        fields: 'close',
        timeframe: this.multiCharts.timeframe
      })

      this.items = items
      this.multiCharts.loadingStatus = 'loaded'
    },
    timeframeChanged () {
      this.getPrices('timeframe')
    },
    daterangeChanged () {
      this.getPrices('daterange')
    },
    starCoin (coin) {
      const index = this.items.findIndex((item) => {
        return item.ticker === coin.ticker
      })

      if (index !== -1) {
        this.items[index].starred = !coin.starred
      }
    },
    singleColumnChanged () {
      this.resizeAnchor = Date.now()
    }
  }
}
</script>

