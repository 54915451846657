import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export const useFiltersStore = defineStore({
  id: 'filters',
  state: () => ({
    multiCharts: {
      loadingStatus: '',
      timeframe: '15m',
      daterange: 24,
      startDateUnix: dayjs().utc().subtract(24, 'hours').unix(),
      endDateUnix: dayjs().utc().unix(),
      singleColumn: false
    }
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: [
          'multiCharts'
        ]
      }
    ]
  }
})
