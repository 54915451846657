<script setup>
import { inject } from 'vue'
import { useFiltersStore } from '@/stores/filters'
import { storeToRefs } from 'pinia'
import SelectInput from '@/components/Inputs/SelectInput.vue'

const emitter = inject('emitter')
const timeframes = [
  {
    title: '5m',
    value: '5m'
  },
  {
    title: '15m',
    value: '15m'
  },
  {
    title: '30m',
    value: '30m'
  },
  {
    title: '1h',
    value: '1h'
  }
]

const store = useFiltersStore()

const { multiCharts } = storeToRefs(store)

function timeframeSelected (value, oldValue) {
  if (oldValue === store.multiCharts.timeframe) return

  emitter.emit('multi-charts-timeframe', value)
}
</script>

<template>
  <SelectInput
    v-model:selected="multiCharts.timeframe"
    class="w-40"
    label="Timeframe"
    :is-initial-loading="multiCharts.loadingStatus === 'initial-loading'"
    :is-loading="multiCharts.loadingStatus === 'timeframe-loading'"
    :options="timeframes"
    @selected="timeframeSelected"/>
</template>
