const coinLogos = new Map([
  ['1INCH', '1INCH.svg'],
  ['2KEY', '2KEY.svg'],
  ['AAVE', 'AAVE.svg'],
  ['ABBC', 'ABBC.svg'],
  ['ABT', 'ABT.svg'],
  ['ACH', 'ACH.svg'],
  ['ACT', 'ACT.svg'],
  ['ADA', 'ADA.svg'],
  ['ADD', 'ADD.svg'],
  ['ADK', 'ADK.svg'],
  ['ADX', 'ADX.svg'],
  ['AE', 'AE.svg'],
  ['AEON', 'AEON.svg'],
  ['AGIX', 'AGIX.svg'],
  ['AGRS', 'AGRS.svg'],
  ['AION', 'AION.svg'],
  ['AKRO', 'AKRO.svg'],
  ['AKT', 'AKT.svg'],
  ['ALGO', 'ALGO.svg'],
  ['ALPHA', 'ALPHA.svg'],
  ['AMB', 'AMB.svg'],
  ['AMP', 'AMP.svg'],
  ['AMPL', 'AMPL.svg'],
  ['ANKR', 'ANKR.svg'],
  ['ANT', 'ANT.svg'],
  ['ANY', 'ANY.svg'],
  ['AOA', 'AOA.svg'],
  ['APE', 'APE.svg'],
  ['APL', 'APL.svg'],
  ['APPC', 'APPC.svg'],
  ['APT', 'APT.svg'],
  ['AR', 'AR.svg'],
  ['ARDR', 'ARDR.svg'],
  ['ARK', 'ARK.svg'],
  ['ARNX', 'ARNX.svg'],
  ['ARPA', 'ARPA.svg'],
  ['ARRR', 'ARRR.svg'],
  ['ASAFE', 'ASAFE.svg'],
  ['AST', 'AST.svg'],
  ['ATOM', 'ATOM.svg'],
  ['AVAX', 'AVAX.svg'],
  ['AXS', 'AXS.svg'],
  ['BabyDoge', 'BabyDoge.svg'],
  ['BAKE', 'BAKE.svg'],
  ['BAL', 'BAL.svg'],
  ['BAN', 'BAN.svg'],
  ['BAND', 'BAND.svg'],
  ['BAT', 'BAT.svg'],
  ['BCD', 'BCD.svg'],
  ['BCH', 'BCH.svg'],
  ['BCN', 'BCN.svg'],
  ['BCPT', 'BCPT.svg'],
  ['BEAM', 'BEAM.svg'],
  ['BELA', 'BELA.svg'],
  ['BEP-20', 'BEP-20.svg'],
  ['BF', 'BF.svg'],
  ['BICO', 'BICO.svg'],
  ['BIFI', 'BIFI.svg'],
  ['BIT', 'BIT.svg'],
  ['BITB', 'BITB.svg'],
  ['BIX', 'BIX.svg'],
  ['BLK', 'BLK.svg'],
  ['BLOCK', 'BLOCK.svg'],
  ['BLZ', 'BLZ.svg'],
  ['BNANA', 'BNANA.svg'],
  ['BNB', 'BNB.svg'],
  ['BNT', 'BNT.svg'],
  ['BNTY', 'BNTY.svg'],
  ['BNX', 'BNX.svg'],
  ['BOND', 'BOND.svg'],
  ['BRD', 'BRD.svg'],
  ['BSD', 'BSD.svg'],
  ['BSV', 'BSV.svg'],
  ['BSW', 'BSW.svg'],
  ['BTC', 'BTC.svg'],
  ['BTCP', 'BTCP.svg'],
  ['BTCZ', 'BTCZ.svg'],
  ['BTDX', 'BTDX.svg'],
  ['BTG', 'BTG.svg'],
  ['BTM', 'BTM.svg'],
  ['BTO', 'BTO.svg'],
  ['BTRST', 'BTRST.svg'],
  ['BTS', 'BTS.svg'],
  ['BTT', 'BTT.svg'],
  ['BTTOLD', 'BTTOLD.svg'],
  ['BTX', 'BTX.svg'],
  ['BUSD', 'BUSD.svg'],
  ['BZRX', 'BZRX.svg'],
  ['C98', 'C98.svg'],
  ['CAKE', 'CAKE.svg'],
  ['CATS', 'CATS.svg'],
  ['CCE', 'CCE.svg'],
  ['CDAI', 'CDAI.svg'],
  ['CEL', 'CEL.svg'],
  ['CELO', 'CELO.svg'],
  ['CENNZ', 'CENNZ.svg'],
  ['CHAT', 'CHAT.svg'],
  ['CHZ', 'CHZ.svg'],
  ['CKB', 'CKB.svg'],
  ['CLAM', 'CLAM.svg'],
  ['CLOAK', 'CLOAK.svg'],
  ['CLT', 'CLT.svg'],
  ['CMM', 'CMM.svg'],
  ['CMT', 'CMT.svg'],
  ['CND', 'CND.svg'],
  ['COB', 'COB.svg'],
  ['COCOS', 'COCOS.svg'],
  ['COLX', 'COLX.svg'],
  ['COMP', 'COMP.svg'],
  ['COTI', 'COTI.svg'],
  ['COW', 'COW.svg'],
  ['CREAM', 'CREAM.svg'],
  ['CRO', 'CRO.svg'],
  ['CRPT', 'CRPT.svg'],
  ['CRV', 'CRV.svg'],
  ['CRW', 'CRW.svg'],
  ['CS', 'CS.svg'],
  ['CSPR', 'CSPR.svg'],
  ['CTSI', 'CTSI.svg'],
  ['CTXC', 'CTXC.svg'],
  ['CUSDC', 'CUSDC.svg'],
  ['CUSDT', 'CUSDT.svg'],
  ['CVC', 'CVC.svg'],
  ['CVT', 'CVT.svg'],
  ['CVX', 'CVX.svg'],
  ['DAI', 'DAI.svg'],
  ['DASH', 'DASH.svg'],
  ['DATA', 'DATA.svg'],
  ['DBC', 'DBC.svg'],
  ['DCN', 'DCN.svg'],
  ['DCR', 'DCR.svg'],
  ['DDX', 'DDX.svg'],
  ['DENT', 'DENT.svg'],
  ['DESO', 'DESO.svg'],
  ['DFI', 'DFI.svg'],
  ['DGB', 'DGB.svg'],
  ['DGD', 'DGD.svg'],
  ['DIVI', 'DIVI.svg'],
  ['DLT', 'DLT.svg'],
  ['DMD', 'DMD.svg'],
  ['DMT', 'DMT.svg'],
  ['DNT', 'DNT.svg'],
  ['DOBO', 'DOBO.svg'],
  ['DOCK', 'DOCK.svg'],
  ['DOGE', 'DOGE.svg'],
  ['DOT', 'DOT.svg'],
  ['DRC', 'DRC.svg'],
  ['DRGN', 'DRGN.svg'],
  ['DRT', 'DRT.svg'],
  ['DTA', 'DTA.svg'],
  ['DTH', 'DTH.svg'],
  ['EBST', 'EBST.svg'],
  ['ECA', 'ECA.svg'],
  ['EDG', 'EDG.svg'],
  ['EGLD', 'EGLD.svg'],
  ['EGT', 'EGT.svg'],
  ['EKT', 'EKT.svg'],
  ['ELA', 'ELA.svg'],
  ['ELF', 'ELF.svg'],
  ['ELONGATE', 'ELONGATE.svg'],
  ['EMC', 'EMC.svg'],
  ['EMC2', 'EMC2.svg'],
  ['ENG', 'ENG.svg'],
  ['ENJ', 'ENJ.svg'],
  ['ENS', 'ENS.svg'],
  ['EOS', 'EOS.svg'],
  ['ERG', 'ERG.svg'],
  ['ESBC', 'ESBC.svg'],
  ['ETC', 'ETC.svg'],
  ['ETH', 'ETH.svg'],
  ['ETHIX', 'ETHIX.svg'],
  ['ETN', 'ETN.svg'],
  ['ETP', 'ETP.svg'],
  ['ETZ', 'ETZ.svg'],
  ['EVER', 'EVER.svg'],
  ['EVX', 'EVX.svg'],
  ['EWT', 'EWT.svg'],
  ['EXP', 'EXP.svg'],
  ['FACE', 'FACE.svg'],
  ['FCT', 'FCT.svg'],
  ['FEI', 'FEI.svg'],
  ['FET', 'FET.svg'],
  ['FIL', 'FIL.svg'],
  ['FLOKI', 'FLOKI.svg'],
  ['FLOW', 'FLOW.svg'],
  ['FLUX', 'FLUX.svg'],
  ['FOX', 'FOX.svg'],
  ['FRAX', 'FRAX.svg'],
  ['FSN', 'FSN.svg'],
  ['FTC', 'FTC.svg'],
  ['FTM', 'FTM.svg'],
  ['FTT', 'FTT.svg'],
  ['FUEL', 'FUEL.svg'],
  ['FX', 'FX.svg'],
  ['FXS', 'FXS.svg'],
  ['GALA', 'GALA.svg'],
  ['GAME', 'GAME.svg'],
  ['GAMMA', 'GAMMA.svg'],
  ['GAS', 'GAS.svg'],
  ['GBX', 'GBX.svg'],
  ['GBYTE', 'GBYTE.svg'],
  ['GLM', 'GLM.svg'],
  ['GLS', 'GLS.svg'],
  ['GMX', 'GMX.svg'],
  ['GNO', 'GNO.svg'],
  ['GRC', 'GRC.svg'],
  ['GRIN', 'GRIN.svg'],
  ['GRS', 'GRS.svg'],
  ['GRT', 'GRT.svg'],
  ['GSC', 'GSC.svg'],
  ['GTC', 'GTC.svg'],
  ['GTO', 'GTO.svg'],
  ['GUSD', 'GUSD.svg'],
  ['GVT', 'GVT.svg'],
  ['GXC', 'GXC.svg'],
  ['HAI', 'HAI.svg'],
  ['HAKKA', 'HAKKA.svg'],
  ['HBAR', 'HBAR.svg'],
  ['HC', 'HC.svg'],
  ['HEDG', 'HEDG.svg'],
  ['HERO', 'HERO.svg'],
  ['HEX', 'HEX.svg'],
  ['HIVE', 'HIVE.svg'],
  ['HNS', 'HNS.svg'],
  ['HNT', 'HNT.svg'],
  ['HORD', 'HORD.svg'],
  ['HOT', 'HOT.svg'],
  ['HPB', 'HPB.svg'],
  ['HT', 'HT.svg'],
  ['HTML', 'HTML.svg'],
  ['HUSD', 'HUSD.svg'],
  ['HUSH', 'HUSH.svg'],
  ['HYDRO', 'HYDRO.svg'],
  ['ICP', 'ICP.svg'],
  ['ICX', 'ICX.svg'],
  ['IDEX', 'IDEX.svg'],
  ['IDLE', 'IDLE.svg'],
  ['IGNIS', 'IGNIS.svg'],
  ['IMX', 'IMX.svg'],
  ['INJ', 'INJ.svg'],
  ['INK', 'INK.svg'],
  ['ION', 'ION.svg'],
  ['IOST', 'IOST.svg'],
  ['IOTX', 'IOTX.svg'],
  ['IOV', 'IOV.svg'],
  ['IQ', 'IQ.svg'],
  ['IQN', 'IQN.svg'],
  ['IRIS', 'IRIS.svg'],
  ['ITC', 'ITC.svg'],
  ['JST', 'JST.svg'],
  ['KARMA', 'KARMA.svg'],
  ['KAT', 'KAT.svg'],
  ['KAVA', 'KAVA.svg'],
  ['KBC', 'KBC.svg'],
  ['KCS', 'KCS.svg'],
  ['KDA', 'KDA.svg'],
  ['KEEP', 'KEEP.svg'],
  ['KIM', 'KIM.svg'],
  ['KIN', 'KIN.svg'],
  ['KLAY', 'KLAY.svg'],
  ['KMD', 'KMD.svg'],
  ['KNC', 'KNC.svg'],
  ['KNDC', 'KNDC.svg'],
  ['KRB', 'KRB.svg'],
  ['KSM', 'KSM.svg'],
  ['LA', 'LA.svg'],
  ['LBC', 'LBC.svg'],
  ['LCC', 'LCC.svg'],
  ['LDO', 'LDO.svg'],
  ['LEO', 'LEO.svg'],
  ['LIKE', 'LIKE.svg'],
  ['LINK', 'LINK.svg'],
  ['LKK', 'LKK.svg'],
  ['LOOM', 'LOOM.svg'],
  ['LPT', 'LPT.svg'],
  ['LRC', 'LRC.svg'],
  ['LRG', 'LRG.svg'],
  ['LSK', 'LSK.svg'],
  ['LTC', 'LTC.svg'],
  ['LTO', 'LTO.svg'],
  ['LUN', 'LUN.svg'],
  ['LUNC', 'LUNC.svg'],
  ['LYM', 'LYM.svg'],
  ['MAID', 'MAID.svg'],
  ['MANA', 'MANA.svg'],
  ['MASK', 'MASK.svg'],
  ['MATIC', 'MATIC.svg'],
  ['MDA', 'MDA.svg'],
  ['MDS', 'MDS.svg'],
  ['MDT', 'MDT.svg'],
  ['MED', 'MED.svg'],
  ['MEETONE', 'MEETONE.svg'],
  ['MERGE', 'MERGE.svg'],
  ['META', 'META.svg'],
  ['MINA', 'MINA.svg'],
  ['MIOTA', 'MIOTA.svg'],
  ['MIR', 'MIR.svg'],
  ['MITH', 'MITH.svg'],
  ['MKR', 'MKR.svg'],
  ['MLN', 'MLN.svg'],
  ['MNS', 'MNS.svg'],
  ['MOAC', 'MOAC.svg'],
  ['MOF', 'MOF.svg'],
  ['MONA', 'MONA.svg'],
  ['MPH', 'MPH.svg'],
  ['MSR', 'MSR.svg'],
  ['MTH', 'MTH.svg'],
  ['MTL', 'MTL.svg'],
  ['MVL', 'MVL.svg'],
  ['MWC', 'MWC.svg'],
  ['MXC', 'MXC.svg'],
  ['MYST', 'MYST.svg'],
  ['NAS', 'NAS.svg'],
  ['NAV', 'NAV.svg'],
  ['NCash', 'NCash.svg'],
  ['NCT', 'NCT.svg'],
  ['NEAR', 'NEAR.svg'],
  ['NEBL', 'NEBL.svg'],
  ['NEO', 'NEO.svg'],
  ['NEST', 'NEST.svg'],
  ['NEU', 'NEU.svg'],
  ['NEW', 'NEW.svg'],
  ['NEXO', 'NEXO.svg'],
  ['NFT', 'NFT.svg'],
  ['NGC', 'NGC.svg'],
  ['NIM', 'NIM.svg'],
  ['NIOX', 'NIOX.svg'],
  ['NKN', 'NKN.svg'],
  ['NLG', 'NLG.svg'],
  ['NMC', 'NMC.svg'],
  ['NMR', 'NMR.svg'],
  ['NOIA', 'NOIA.svg'],
  ['NU', 'NU.svg'],
  ['NULS', 'NULS.svg'],
  ['NXS', 'NXS.svg'],
  ['NXT', 'NXT.svg'],
  ['OAX', 'OAX.svg'],
  ['OCEAN', 'OCEAN.svg'],
  ['OCT', 'OCT.svg'],
  ['ODE', 'ODE.svg'],
  ['OGN', 'OGN.svg'],
  ['OK', 'OK.svg'],
  ['OKB', 'OKB.svg'],
  ['Old', 'Old.svg'],
  ['OM', 'OM.svg'],
  ['OMG', 'OMG.svg'],
  ['OMNI', 'OMNI.svg'],
  ['ONE', 'ONE.svg'],
  ['ONG', 'ONG.svg'],
  ['ONT', 'ONT.svg'],
  ['OP', 'OP.svg'],
  ['ORBS', 'ORBS.svg'],
  ['OSMO', 'OSMO.svg'],
  ['OST', 'OST.svg'],
  ['OXT', 'OXT.svg'],
  ['PART', 'PART.svg'],
  ['PASC', 'PASC.svg'],
  ['PAXG', 'PAXG.svg'],
  ['PAY', 'PAY.svg'],
  ['PAYX', 'PAYX.svg'],
  ['pBTC', 'pBTC.svg'],
  ['PCX', 'PCX.svg'],
  ['PEARL', 'PEARL.svg'],
  ['PERL', 'PERL.svg'],
  ['PINK', 'PINK.svg'],
  ['PIVX', 'PIVX.svg'],
  ['PLBT', 'PLBT.svg'],
  ['PLR', 'PLR.svg'],
  ['PLU', 'PLU.svg'],
  ['PMA', 'PMA.svg'],
  ['PNK', 'PNK.svg'],
  ['PNT', 'PNT.svg'],
  ['POE', 'POE.svg'],
  ['POKT', 'POKT.svg'],
  ['POLIS', 'POLIS.svg'],
  ['POLY', 'POLY.svg'],
  ['POOCOIN', 'POOCOIN.svg'],
  ['POT', 'POT.svg'],
  ['POWR', 'POWR.svg'],
  ['PPC', 'PPC.svg'],
  ['PPP', 'PPP.svg'],
  ['PPT', 'PPT.svg'],
  ['PRE', 'PRE.svg'],
  ['PRO', 'PRO.svg'],
  ['PRQ', 'PRQ.svg'],
  ['QASH', 'QASH.svg'],
  ['QKC', 'QKC.svg'],
  ['QLC', 'QLC.svg'],
  ['QNT', 'QNT.svg'],
  ['QRL', 'QRL.svg'],
  ['QSP', 'QSP.svg'],
  ['QTUM', 'QTUM.svg'],
  ['RAMP', 'RAMP.svg'],
  ['RCN', 'RCN.svg'],
  ['RDD', 'RDD.svg'],
  ['RDN', 'RDN.svg'],
  ['REN', 'REN.svg'],
  ['REP', 'REP.svg'],
  ['REQ', 'REQ.svg'],
  ['REV', 'REV.svg'],
  ['RIF', 'RIF.svg'],
  ['RISE', 'RISE.svg'],
  ['RLC', 'RLC.svg'],
  ['ROOK', 'ROOK.svg'],
  ['ROSE', 'ROSE.svg'],
  ['RSR', 'RSR.svg'],
  ['RSV', 'RSV.svg'],
  ['RUFF', 'RUFF.svg'],
  ['RUNE', 'RUNE.svg'],
  ['RVN', 'RVN.svg'],
  ['RYO', 'RYO.svg'],
  ['SAFEMOON', 'SAFEMOON.svg'],
  ['SALT', 'SALT.svg'],
  ['SAN', 'SAN.svg'],
  ['SAND', 'SAND.svg'],
  ['SBD', 'SBD.svg'],
  ['SC', 'SC.svg'],
  ['SCRT', 'SCRT.svg'],
  ['SEELE', 'SEELE.svg'],
  ['SENSO', 'SENSO.svg'],
  ['SFI', 'SFI.svg'],
  ['SFP', 'SFP.svg'],
  ['SHA', 'SHA.svg'],
  ['SHIB', 'SHIB.svg'],
  ['SHIBDOGE', 'SHIBDOGE.svg'],
  ['SHIP', 'SHIP.svg'],
  ['SIB', 'SIB.svg'],
  ['SKY', 'SKY.svg'],
  ['SLP', 'SLP.svg'],
  ['SLS', 'SLS.svg'],
  ['SMART', 'SMART.svg'],
  ['SNOW', 'SNOW.svg'],
  ['SNT', 'SNT.svg'],
  ['SNX', 'SNX.svg'],
  ['SOC', 'SOC.svg'],
  ['SOL', 'SOL.svg'],
  ['SOUL', 'SOUL.svg'],
  ['SPANK', 'SPANK.svg'],
  ['SPHTX', 'SPHTX.svg'],
  ['SRM', 'SRM.svg'],
  ['SRN', 'SRN.svg'],
  ['STAK', 'STAK.svg'],
  ['STAKE', 'STAKE.svg'],
  ['START', 'START.svg'],
  ['STC', 'STC.svg'],
  ['STEEM', 'STEEM.svg'],
  ['stETH', 'stETH.svg'],
  ['STORJ', 'STORJ.svg'],
  ['STRAX', 'STRAX.svg'],
  ['STRONG', 'STRONG.svg'],
  ['STX', 'STX.svg'],
  ['SUB', 'SUB.svg'],
  ['SUMO', 'SUMO.svg'],
  ['SUSHI', 'SUSHI.svg'],
  ['SUTER', 'SUTER.svg'],
  ['SWT', 'SWT.svg'],
  ['SWTH', 'SWTH.svg'],
  ['SYS', 'SYS.svg'],
  ['TAU', 'TAU.svg'],
  ['TBX', 'TBX.svg'],
  ['TCH', 'TCH.svg'],
  ['TEL', 'TEL.svg'],
  ['TEN', 'TEN.svg'],
  ['TERA', 'TERA.svg'],
  ['TERN', 'TERN.svg'],
  ['TFUEL', 'TFUEL.svg'],
  ['THETA', 'THETA.svg'],
  ['THR', 'THR.svg'],
  ['TIX', 'TIX.svg'],
  ['TKN', 'TKN.svg'],
  ['TNB', 'TNB.svg'],
  ['TNC', 'TNC.svg'],
  ['TOMO', 'TOMO.svg'],
  ['TON', 'TON.svg'],
  ['TONCOIN', 'TONCOIN.svg'],
  ['TOR', 'TOR.svg'],
  ['TORN', 'TORN.svg'],
  ['TRAC', 'TRAC.svg'],
  ['TRB', 'TRB.svg'],
  ['TRIBE', 'TRIBE.svg'],
  ['TRTL', 'TRTL.svg'],
  ['TRX', 'TRX.svg'],
  ['TTT', 'TTT.svg'],
  ['TUSD', 'TUSD.svg'],
  ['TWT', 'TWT.svg'],
  ['TZC', 'TZC.svg'],
  ['UBQ', 'UBQ.svg'],
  ['UMA', 'UMA.svg'],
  ['UNI', 'UNI.svg'],
  ['UOS', 'UOS.svg'],
  ['UQC', 'UQC.svg'],
  ['USDC', 'USDC.svg'],
  ['USDD', 'USDD.svg'],
  ['USDP', 'USDP.svg'],
  ['USDT', 'USDT.svg'],
  ['UTK', 'UTK.svg'],
  ['VEIL', 'VEIL.svg'],
  ['VERI', 'VERI.svg'],
  ['VET', 'VET.svg'],
  ['VIA', 'VIA.svg'],
  ['VIBE', 'VIBE.svg'],
  ['VITE', 'VITE.svg'],
  ['VLX', 'VLX.svg'],
  ['VSP', 'VSP.svg'],
  ['VSYS', 'VSYS.svg'],
  ['VTC', 'VTC.svg'],
  ['VTHO', 'VTHO.svg'],
  ['VXV', 'VXV.svg'],
  ['WAN', 'WAN.svg'],
  ['WAVES', 'WAVES.svg'],
  ['WAXP', 'WAXP.svg'],
  ['WBTC', 'WBTC.svg'],
  ['WGR', 'WGR.svg'],
  ['WICC', 'WICC.svg'],
  ['WING', 'WING.svg'],
  ['WINGS', 'WINGS.svg'],
  ['WOO', 'WOO.svg'],
  ['WOW', 'WOW.svg'],
  ['WPR', 'WPR.svg'],
  ['WTC', 'WTC.svg'],
  ['XAS', 'XAS.svg'],
  ['XAUT', 'XAUT.svg'],
  ['XBC', 'XBC.svg'],
  ['XBY', 'XBY.svg'],
  ['XCH', 'XCH.svg'],
  ['XCM', 'XCM.svg'],
  ['XCN', 'XCN.svg'],
  ['XCP', 'XCP.svg'],
  ['XDB', 'XDB.svg'],
  ['XDN', 'XDN.svg'],
  ['XEC', 'XEC.svg'],
  ['XEM', 'XEM.svg'],
  ['XHT', 'XHT.svg'],
  ['XHV', 'XHV.svg'],
  ['XIN', 'XIN.svg'],
  ['XLM', 'XLM.svg'],
  ['XMR', 'XMR.svg'],
  ['XMX', 'XMX.svg'],
  ['XMY', 'XMY.svg'],
  ['XNO', 'XNO.svg'],
  ['XOR', 'XOR.svg'],
  ['XPA', 'XPA.svg'],
  ['XPM', 'XPM.svg'],
  ['XPR', 'XPR.svg'],
  ['XRP', 'XRP.svg'],
  ['XSR', 'XSR.svg'],
  ['XTZ', 'XTZ.svg'],
  ['XUC', 'XUC.svg'],
  ['XVG', 'XVG.svg'],
  ['XWC', 'XWC.svg'],
  ['XYM', 'XYM.svg'],
  ['XYO', 'XYO.svg'],
  ['YFI', 'YFI.svg'],
  ['YOOSHI', 'YOOSHI.svg'],
  ['YOYOW', 'YOYOW.svg'],
  ['ZB', 'ZB.svg'],
  ['ZCL', 'ZCL.svg'],
  ['ZEC', 'ZEC.svg'],
  ['ZIL', 'ZIL.svg'],
  ['ZLW', 'ZLW.svg'],
  ['ZRX', 'ZRX.svg'],
  ['RNDR', 'RNDR.svg'],
  ['SSV', 'SSV.svg'],
  ['RPL', 'RPL.png'],
  ['CFX', 'CFX.svg'],
  ['XDC', 'XDC.svg'],
  ['DYDX', 'DYDX.svg'],
  ['GT', 'GT.svg'],
  ['FLR', 'FLR.svg'],
  ['ETHW', 'ETHW.png']
])

export {
  coinLogos
}
