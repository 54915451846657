<script setup>
import { defineExpose, defineProps, onMounted, onUnmounted, ref, watch, nextTick } from 'vue'
import { createChart } from 'lightweight-charts'

const seriesOptions = {
  lineWidth: 2,
  lineType: 0,
  color: '#0D160B',
  priceLineVisible: false,
  crosshairMarkerVisible: false
}

const props = defineProps({
  type: {
    type: String,
    default: 'line'
  },
  resizeAnchor: {
    type: Number,
    default: null
  },
  data: {
    type: Array,
    required: true
  },
  autosize: {
    default: true,
    type: Boolean
  },
  chartOptions: {
    type: Object,
    default: () => ({
      handleScroll: false,
      handleScale: false,
      rightPriceScale: {
        visible: false
      },
      timeScale: {
        visible: false
      },
      grid: {
        horzLines: {
          visible: false
        },
        vertLines: {
          visible: false
        }
      },
      crosshair: {
        vertLine: {
          visible: false
        },
        horzLine: {
          visible: false
        }
      }
    })
  },
  seriesOptions: {
    type: Object,
    default: () => ({})
  },
  timeScaleOptions: {
    type: Object,
    default: () => {}
  },
  priceScaleOptions: {
    type: Object,
    default: () => {}
  }
})

// Function to get the correct series constructor name for current series type.
function getChartSeriesConstructorName (type) {
  return `add${type.charAt(0).toUpperCase() + type.slice(1)}Series`
}

// Lightweight Chart instances are stored as normal JS variables
// If you need to use a ref then it is recommended that you use `shallowRef` instead
let series
let chart

const chartContainer = ref()

const fitContent = () => {
  if (!chart) return
  chart.timeScale().fitContent()
}

const getChart = () => {
  return chart
}

defineExpose({
  fitContent,
  getChart
})

// Auto resizes the chart when the browser window is resized.
const resizeHandler = () => {
  if (!chart || !chartContainer.value) return

  const dimensions = chartContainer.value.getBoundingClientRect()


  chart.resize(dimensions.width, dimensions.height)
  chart.timeScale().fitContent()
}

// Creates the chart series and sets the data.
const addSeriesAndData = props => {
  const seriesConstructor = getChartSeriesConstructorName(props.type)
  series = chart[seriesConstructor]({
    ...seriesOptions,
    ...props.seriesOptions
  })
  series.setData(props.data)
}

onMounted(() => {
  // Create the Lightweight Charts Instance using the container ref.
  chart = createChart(chartContainer.value, props.chartOptions)
  addSeriesAndData(props)

  if (props.priceScaleOptions) {
    chart.priceScale().applyOptions(props.priceScaleOptions)
  }

  if (props.timeScaleOptions) {
    chart.timeScale().applyOptions(props.timeScaleOptions)
  }

  chart.timeScale().fitContent()
})

onUnmounted(() => {
  if (chart) {
    chart.remove()
    chart = null
  }
  if (series) {
    series = null
  }
})

watch(
  () => props.resizeAnchor, async () => {
    await nextTick()
    resizeHandler()
  }
)
watch(
  () => props.data,
  newData => {
    if (!series) return
    series.setData(newData)
    chart.timeScale().fitContent()
  }
)
</script>

<template>
  <div
    ref="chartContainer"
    class="h-[130px] w-full"/>
</template>
