<template>
  <div class="custom-select">
    <label
      :for="id"
      class="text-xs leading-4 text-slate-800">
      {{ label }}
    </label>

    <div class="relative">
      <button
        :id="id"
        :disabled="isLoading || isInitialLoading"
        type="button"
        :class="{
          'border-blue-300 ring-2 ring-blue-200 ring-opacity-50': isOpen,
          'skeleton-loading': isLoading || isInitialLoading
        }"
        class="disabled:cursor-not-allowed flex flex-wrap text-sm text-slate-800 placeholder-gray-400 bg-white relative w-full border border-gray-300 shadow-none rounded pl-1.5 pr-8 py-0.5 text-left cursor-default focus:border-blue-300 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50"
        @click.stop="toggle">
        <span
          v-show="selected"
          class="flex items-center leading-sm text-gray-700">
          {{ selectedTitle }}
        </span>

        <span
          v-show="!selected"
          class="block truncate text-gray-500">
          Select
        </span>

        <span
          v-show="isLoading"
          class="absolute inset-y-0 right-0 flex items-center pr-2.5">
          <span class="!border-2 w-4 h-4 loader"/>
        </span>

        <span
          v-show="!isLoading"
          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg
            class="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true">
            <path
              fill-rule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"/>
          </svg>
        </span>
      </button>

      <ul
        v-if="isOpen"
        v-click-outside="close"
        class="absolute z-10 mt-1.5 w-full bg-white max-h-60 rounded-md py-1 text-base ring-1 ring-blue-800 ring-opacity-10 overflow-auto focus:outline-none sm:text-sm"
        tabindex="-1"
        role="listbox">
        <li
          v-for="(item, index) in options"
          :key="`option_${index}`"
          class="text-gray-900 cursor-default select-none relative py-0.5 pl-3 pr-9 hover:text-white hover:bg-blue-600"
          role="option"
          @click="select(item)">
          <span
            :class="[item.value === selected ? 'font-semibold' : 'font-normal']"
            class="block truncate">
            {{ item.title }}
          </span>

          <span
            v-show="item.value === selected"
            class="text-sky-700 absolute inset-y-0 right-0 flex items-center pr-4">
            <InlineSvg
              class="h-4 w-4"
              :src="require('@/assets/fontawesome/sharp-solid/check.svg')"/>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { clickOutside } from '@/directives/click-outside'
import { uid } from 'radash'

export default {
  name: 'SelectInput',
  components: {},
  directives: {
    clickOutside
  },
  props: {
    label: {
      type: String,
      default: 'Select',
      required: true
    },
    // [{ title: '', value: '' }, ...]
    options: {
      type: Array,
      default: () => [],
      required: true
    },
    selected: {
      type: [String, Number, null],
      default: () => {},
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isInitialLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:selected', 'selected'],
  data () {
    return {
      id: 'select_' + uid(6),
      isOpen: false
    }
  },
  computed: {
    selectedTitle () {
      return this.options.find(item => (item.value === this.selected))?.title
    }
  },
  mounted () {},
  methods: {
    toggle () {
      this.isOpen = !this.isOpen
    },
    close () {
      this.isOpen = false
    },
    select (item) {
      const oldValue = structuredClone(this.selected)

      this.$emit('update:selected', item.value)
      this.$emit('selected', item.value, oldValue)
      this.close()
    }
  }
}
</script>

<style>
  .custom-select li:hover svg {
    color: white;
  }
</style>
