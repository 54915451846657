<script setup>
import { defineProps, ref, watch, defineEmits, computed } from 'vue'
import MultiChartsChart from '@/components/MultiCharts/Includes/MultiChartsChart.vue'
import { datesDifference, isDaterangeLoading } from '@/components/MultiCharts/helpers'

const emit = defineEmits(['star'])

const props = defineProps({
  item: {
    type: Object,
    required: true,
    default: () => {}
  },
  resizeAnchor: {
    type: Number,
    default: null
  }
})

const daterange = ref(datesDifference.value)

watch(isDaterangeLoading, (value) => {
  if (!value) {
    daterange.value = datesDifference.value
  }
})

const starSvgName = computed(() => {
  return props.item.starred ? 'star-filled.svg' : 'star.svg'
})

function prepareDataForChart (candles) {
  return candles.map(item => {
    return {
      value: item.close,
      time: item.time
    }
  })
}
</script>

<template>
  <div class="border border-gray-200 rounded-lg">
    <div class="px-2 pt-2">
      <div class="flex items-center justify-between w-full">
        <h3>{{ item.ticker }}</h3>

        <button
          type="button"
          class="p-0.5"
          @click="emit('star', item)">
          <InlineSvg
            :class="[item.starred ? 'text-amber-400' : 'text-gray-500']"
            class="w-4 h-4"
            :src="require(`@/assets/svg/${starSvgName}`)"/>
        </button>
      </div>

      <b
        :class="{
          'text-[#22c55e]': item.price_change > 0,
          'text-[#ef4444]': item.price_change < 0,
          'text-[#cdcfd5]': item.price_change === 0
        }">
        <span v-show="item.price_change > 0">+</span>{{ item.price_change }}%
      </b>

      <span class="ml-1 font-semibold">{{ daterange }}h</span>
    </div>

    <MultiChartsChart
      class="rounded-lg overflow-hidden px-0.5"
      type="line"
      :data="prepareDataForChart(item.candles)"
      :resize-anchor="resizeAnchor"
      :series-options="{
        color: '#0D160B'
      }"/>
  </div>
</template>
