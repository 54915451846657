<template>
  <div class="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-6">
    <div class="flex items-end lg:items-start lg:flex-col gap-2">
      <SearchFilter
        v-model:search="searchString"
        :initial-value="searchString"
        name="Search"
        :timeout="500"
        placeholder="Search by ticker"/>

      <fieldset class="mb-1">
        <legend class="sr-only">
          Starred coins filter
        </legend>

        <div class="relative flex items-start">
          <div class="flex h-5 items-center">
            <input
              id="starred_filter"
              v-model="starredCoinsOnly"
              name="starred"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-500">
          </div>

          <div class="ml-3 text-sm">
            <label for="starred_filter" class="font-medium text-gray-700">
              Only starred coins
            </label>
          </div>
        </div>
      </fieldset>
    </div>

    <DateRangeFilter
      :name="'Date range (UTC)'"
      :date-range="filtersCurrentValues.daterange"
      :date-range-min-max="filtersMinMaxValues.daterange"
      @submit="applyDateRange"/>

    <div class="flex flex-col gap-2">
      <FromToFilter
        :step="0.01"
        :range="userSettings.filters.market_dominance || filtersCurrentValues.market_dominance"
        :range-min-max="filtersMinMaxValues.market_dominance"
        name="Market Dominance"
        @submit="applyMarketDominanceFilter"/>

      <fieldset class="mb-1">
        <legend class="sr-only">
          Show only exchange coins filter
        </legend>

        <div class="relative flex items-start">
          <div class="flex h-5 items-center">
            <input
              id="exchange_coins_only"
              v-model="exchangeCoinsOnly"
              name="exchange_coins_only"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-500">
          </div>

          <div class="ml-3 text-sm">
            <label
              for="exchange_coins_only"
              class="font-medium text-gray-700">
              Show only exchange coins
            </label>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useSocialActivityStore } from '@/stores/social-activity'
import { useAuthStore } from '@/stores/auth'
import SearchFilter from '@/components/Filters/SearchFilter'
import DateRangeFilter from '@/components/Filters/DateRangeFilter.vue'
import FromToFilter from '@/components/Filters/FromToFilter'

export default {
  name: 'SocialActivityFilters',
  components: {
    SearchFilter,
    DateRangeFilter,
    FromToFilter
  },
  data () {
    return {}
  },
  computed: {
    ...mapWritableState(useSocialActivityStore, [
      'searchString',
      'filtersCurrentValues',
      'starredCoinsOnly',
      'filters',
      'exchangeCoinsOnly'
    ]),
    ...mapState(useSocialActivityStore, [
      'loadingStatus',
      'filtersMinMaxValues'
    ]),
    ...mapState(useAuthStore, [
      'userSettings'
    ])
  },
  watch: {
    searchString (value, oldValue) {
      if (value !== oldValue) {
        this.getSocialActivityData('filter')
      }
    },
    starredCoinsOnly (value, oldValue) {
      if (value !== oldValue) {
        this.getSocialActivityData('filter')
      }
    },
    exchangeCoinsOnly (value, oldValue) {
      if (value !== oldValue) {
        this.getSocialActivityData('filter')
      }
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions(useSocialActivityStore, [
      'getSocialActivityData'
    ]),
    applyDateRange (dateRange) {
      this.filters.daterange = dateRange

      this.getSocialActivityData('filter')
    },
    applyMarketDominanceFilter (range) {
      this.filters.market_dominance = range

      this.getSocialActivityData('filter')
    }
  }
}
</script>

