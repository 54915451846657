<script setup>
import { inject } from 'vue'
import { useFiltersStore } from '@/stores/filters'
import SelectInput from '@/components/Inputs/SelectInput.vue'
import { storeToRefs } from 'pinia'
import dayjs from 'dayjs'

const emitter = inject('emitter')

const store = useFiltersStore()
const { multiCharts } = storeToRefs(store)

const dateRanges = [
  {
    title: 'Latest 4 hours',
    value: 4
  },
  {
    title: 'Latest 8 hours',
    value: 8
  },
  {
    title: 'Latest 12 hours',
    value: 12
  },
  {
    title: 'Latest day',
    value: 24
  },
  {
    title: 'Latest 3 days',
    value: 24 * 3
  }
]

function daterangeSelected (value, oldValue) {
  if (oldValue === store.multiCharts.daterange) return

  store.multiCharts.startDateUnix = dayjs().utc().subtract(value, 'hours').unix()
  store.multiCharts.endDateUnix = dayjs().utc().unix()

  emitter.emit('multi-charts-daterange', value)
}

</script>

<template>
  <SelectInput
    v-model:selected="multiCharts.daterange"
    class="w-40"
    label="Daterange"
    :is-initial-loading="multiCharts.loadingStatus === 'initial-loading'"
    :is-loading="multiCharts.loadingStatus === 'daterange-loading'"
    :options="dateRanges"
    @selected="daterangeSelected"/>
</template>
