import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useSocialActivityStore } from '@/stores/social-activity'
import { post } from '@/helpers/fetch'

import HomeView from '@/views/HomeView.vue'
import CorrelationsView from '@/views/CorrelationsView'
import LoginView from '@/views/LoginView'
import CoinCardView from '@/views/CoinCardView'
import MultiChartsView from '@/views/MultiChartsView.vue'
import SocialActivityView from '@/views/SocialActivityView'
import AccountView from '@/views/AccountView.vue'

// Show page only for guests (For example: /signIn)
const ifNotAuthenticated = (to, from, next) => {
  const authStore = useAuthStore()

  if (authStore.token) {
    next('/')
  } else {
    next()
  }
}

// Allow only logged-in users to see page
const ifAuthenticated = async (to, from, next) => {
  const authStore = useAuthStore()
  const socialActivityStore = useSocialActivityStore()

  if (authStore.token) {
    try {
      const response = await post('/login', {
        token: authStore.token
      })

      authStore.userSettings = response.user_settings
      authStore.rights = response.rights
      authStore.username = response.username

      socialActivityStore.filters.market_dominance = response.user_settings.filters.market_dominance

      next()
    } catch (e) {
      authStore.token = null
      next('/login')
    }
  } else {
    // Save path to redirect after login
    if (['SocialActivity', 'Correlations'].includes(to.name)) {
      authStore.pathToRedirectAfterLogin = to.fullPath
    }

    // console.log('to: ' + to.path, 'from: ' + from.path)
    // console.log('to: ' + to.name)

    next('/login')
  }
}

const onlyAdmins = async (to, from, next) => {
  const authStore = useAuthStore()

  if (authStore.token && authStore.rights === 'admin') {
    next()
  } else {
    next('/')
  }
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    beforeEnter: [ifNotAuthenticated]
  },
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    beforeEnter: [ifAuthenticated]
  },
  {
    path: '/social-activity',
    name: 'SocialActivity',
    component: SocialActivityView,
    beforeEnter: [ifAuthenticated]
  },
  {
    path: '/:ticker',
    name: 'CoinCard',
    component: CoinCardView,
    beforeEnter: [ifAuthenticated]
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "test" */ '../views/TestView.vue'),
    beforeEnter: [ifAuthenticated]
  },
  {
    path: '/correlations',
    name: 'Correlations',
    component: CorrelationsView,
    beforeEnter: [ifAuthenticated]
  },
  {
    path: '/multi-charts',
    name: 'Price changes',
    component: MultiChartsView,
    beforeEnter: [ifAuthenticated]
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountView,
    beforeEnter: [ifAuthenticated]
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '../views/UsersView.vue'),
    beforeEnter: [ifAuthenticated, onlyAdmins]
  },
  {
    path: '/add-user',
    name: 'AddUser',
    component: () => import(/* webpackChunkName: "add-user" */ '../views/UsersAddView.vue'),
    beforeEnter: [ifAuthenticated, onlyAdmins]
  },
  {
    path: '/edit-user/:id',
    name: 'EditUser',
    component: () => import(/* webpackChunkName: "edit-user" */ '../views/UsersEditView.vue'),
    beforeEnter: [ifAuthenticated, onlyAdmins]
  },
  {
    path: '/user/:id',
    name: 'User',
    component: () => import(/* webpackChunkName: "edit-user" */ '../views/UserView.vue'),
    beforeEnter: [ifAuthenticated, onlyAdmins]
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "news" */ '../views/NewsView.vue'),
    beforeEnter: [ifAuthenticated]
  },
  {
    path: '/tops',
    name: 'Tops',
    component: () => import(/* webpackChunkName: "tops" */ '../views/TopsView.vue'),
    beforeEnter: [ifAuthenticated]
  },
  {
    path: '/dev-dashboard',
    name: 'DevDashboard',
    component: () => import(/* webpackChunkName: "dev-dashboard" */ '../views/DevDashboardView.vue'),
    beforeEnter: [ifAuthenticated, onlyAdmins]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   next()
// })

export default router
