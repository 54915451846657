<template>
  <form @submit.prevent="submit">
    <span class="text-xs leading-4 text-slate-800">
      {{ name }}
    </span>

    <div class="flex items-center">
      <input
        v-model="dateFrom"
        autocomplete="off"
        type="text"
        :class="[isStartDateValid ?
          'focus:border-blue-300 focus:ring-blue-200' :
          'border-rose-300 ring-2 ring-rose-200 ring-opacity-50 focus:border-rose-300 focus:ring-rose-200']"
        class="block max-w-[140px] lg:max-w-[160px] text-sm text-slate-800 placeholder-gray-400 px-1.5 py-0.5 bg-white rounded border-gray-300 shadow-none focus:ring-2 focus:ring-opacity-50"
        :placeholder="startDatePlaceholder">

      <span class="mx-1 text-slate-800">to</span>

      <input
        v-model="dateTo"
        autocomplete="off"
        type="text"
        :class="[isEndDateValid ?
          'focus:border-blue-300 focus:ring-blue-200' :
          'border-rose-300 ring-2 ring-rose-200 ring-opacity-50 focus:border-rose-300 focus:ring-rose-200']"
        class="block max-w-[140px] lg:max-w-[160px] text-sm text-slate-800 placeholder-gray-400 px-1.5 py-0.5 bg-white rounded border-gray-300 shadow-none focus:ring-2 focus:ring-opacity-50"
        :placeholder="endDatePlaceholder">

      <button
        class="ml-1.5 text-sm text-white rounded px-1.5 py-0.5 bg-sky-500 border border-sky-500 hover:border-sky-600 hover:bg-sky-600"
        type="submit">
        Go
      </button>
    </div>

    <div v-if="dateRangeMinMax.to" class="mt-1 space-x-1">
      <button
        type="button"
        class="text-sm rounded px-1.5 py-0.5 text-slate-800 border w-[85px]"
        :class="[isLastDay ? 'border-blue-300 ring-1 ring-blue-300' : 'border-gray-300']"
        @click="selectLatestDay">
        Latest day
      </button>

      <button
        type="button"
        class="text-sm rounded px-1.5 py-0.5 text-slate-800 border"
        :class="[isLastTwoDays ? 'border-blue-300 ring-1 ring-blue-300' : 'border-gray-300']"
        @click="selectLatestTwoDays">
        Latest two days
      </button>

      <button
        type="button"
        class="text-sm rounded px-1.5 py-0.5 text-slate-800 border"
        :class="[isLastThreeDays ? 'border-blue-300 ring-1 ring-blue-300' : 'border-gray-300']"
        @click="selectLatestThreeDays">
        Latest three days
      </button>
    </div>
  </form>
</template>

<script>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)
dayjs.extend(utc)

export default {
  name: 'DateRangeFilter',
  components: {},
  props: {
    name: {
      type: String,
      default: 'Date'
    },
    // from, to - are timestamp seconds
    dateRange: {
      type: Object,
      default: () => ({
        from: null,
        to: null
      })
    },
    dateRangeMinMax: {
      type: Object,
      default: () => ({
        from: null,
        to: null
      })
    },
    allowDebounceSearch: {
      type: Boolean,
      default: false
    },
    timeout: {
      type: Number,
      default: 500
    }
  },
  emits: ['submit'],
  data () {
    return {
      isStartDateValid: true,
      isEndDateValid: true,
      dateFrom: '',
      dateTo: ''
    }
  },
  computed: {
    dateRangeString () {
      return JSON.stringify(this.dateRange)
    },
    startDatePlaceholder () {
      if (this.dateRangeMinMax.from) {
        const date = dayjs.unix(this.dateRangeMinMax.from).utc()

        return date.format('YYYY-MM-DD HH:mm')
      } else {
        return 'YYYY-MM-DD HH:mm'
      }
    },
    endDatePlaceholder () {
      if (this.dateRangeMinMax.to) {
        const date = dayjs.unix(this.dateRangeMinMax.to).utc()

        return date.format('YYYY-MM-DD HH:mm')
      } else {
        return 'YYYY-MM-DD HH:mm'
      }
    },
    latestAvailableDate () {
      return dayjs.unix(this.dateRangeMinMax.to).utc()
    },
    dayAgo () {
      return dayjs.unix(this.dateRangeMinMax.to).utc().subtract(1, 'day')
    },
    twoDaysAgo () {
      return dayjs.unix(this.dateRangeMinMax.to).utc().subtract(2, 'day')
    },
    threeDaysAgo () {
      return dayjs.unix(this.dateRangeMinMax.to).utc().subtract(3, 'day')
    },
    isLastDay () {
      const dateFrom = dayjs.utc(this.dateFrom, 'YYYY-MM-DD HH:mm')
      const dateTo = dayjs.utc(this.dateTo, 'YYYY-MM-DD HH:mm')

      return dateFrom.unix() === this.dayAgo.unix() && dateTo.unix() === this.latestAvailableDate.unix()
    },
    isLastTwoDays () {
      const dateFrom = dayjs.utc(this.dateFrom, 'YYYY-MM-DD HH:mm')
      const dateTo = dayjs.utc(this.dateTo, 'YYYY-MM-DD HH:mm')

      return dateFrom.unix() === this.twoDaysAgo.unix() && dateTo.unix() === this.latestAvailableDate.unix()
    },
    isLastThreeDays () {
      const dateFrom = dayjs.utc(this.dateFrom, 'YYYY-MM-DD HH:mm')
      const dateTo = dayjs.utc(this.dateTo, 'YYYY-MM-DD HH:mm')

      return dateFrom.unix() === this.threeDaysAgo.unix() && dateTo.unix() === this.latestAvailableDate.unix()
    }
  },
  watch: {
    dateRangeString (value, oldValue) {
      if (value !== oldValue) {
        this.prepareDaterange()
      }
    }
  },
  beforeMount () {
    this.prepareDaterange()
  },
  methods: {
    prepareDaterange () {
      if (this.dateRange.from) {
        // https://day.js.org/docs/en/parse/unix-timestamp
        const date = dayjs.unix(this.dateRange.from).utc()

        if (date.isValid()) {
          this.dateFrom = date.format('YYYY-MM-DD HH:mm')
        }
      }

      if (this.dateRange.to) {
        const date = dayjs.unix(this.dateRange.to).utc()

        if (date.isValid()) {
          this.dateTo = date.format('YYYY-MM-DD HH:mm')
        }
      }
    },
    validate (date, format) {
      return dayjs(date, format).format(format) === date
    },
    submit () {
      this.isStartDateValid = this.validate(this.dateFrom, 'YYYY-MM-DD HH:mm')
      this.isEndDateValid = this.validate(this.dateTo, 'YYYY-MM-DD HH:mm')

      if (this.isStartDateValid && this.isEndDateValid) {
        const dateFrom = dayjs.utc(this.dateFrom, 'YYYY-MM-DD HH:mm')
        const dateTo = dayjs.utc(this.dateTo, 'YYYY-MM-DD HH:mm')

        this.$emit('submit', {
          from: dateFrom.unix(),
          to: dateTo.unix()
        })
      }
    },
    selectLatestDay () {
      this.dateFrom = this.dayAgo.format('YYYY-MM-DD HH:mm')
      this.dateTo = this.latestAvailableDate.format('YYYY-MM-DD HH:mm')

      this.$emit('submit', {
        from: this.dayAgo.unix(),
        to: this.latestAvailableDate.unix()
      })
    },
    selectLatestTwoDays () {
      this.dateFrom = this.twoDaysAgo.format('YYYY-MM-DD HH:mm')
      this.dateTo = this.latestAvailableDate.format('YYYY-MM-DD HH:mm')

      this.$emit('submit', {
        from: this.twoDaysAgo.unix(),
        to: this.latestAvailableDate.unix()
      })
    },
    selectLatestThreeDays () {
      this.dateFrom = this.threeDaysAgo.format('YYYY-MM-DD HH:mm')
      this.dateTo = this.latestAvailableDate.format('YYYY-MM-DD HH:mm')

      this.$emit('submit', {
        from: this.threeDaysAgo.unix(),
        to: this.latestAvailableDate.unix()
      })
    }
  }
}
</script>

