<script setup>
import { inject } from 'vue'
import { useFiltersStore } from '@/stores/filters'
import { storeToRefs } from 'pinia'
const emitter = inject('emitter')

const store = useFiltersStore()
const { multiCharts } = storeToRefs(store)

function toggle () {
  multiCharts.value.singleColumn = !multiCharts.value.singleColumn
  emitter.emit('multi-charts-single-column')
}
</script>

<template>
  <div class="flex items-center">
    <button
      type="button"
      :class="[multiCharts.singleColumn ? 'bg-blue-400' : 'bg-gray-200']"
      class="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2"
      role="switch"
      :aria-checked="multiCharts.singleColumn"
      aria-labelledby="single-column-label"
      @click="toggle">
      <span class="sr-only">Single column toggle</span>

      <span
        aria-hidden="true"
        :class="[multiCharts.singleColumn ? 'translate-x-5' : 'translate-x-0']"
        class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"/>
    </button>

    <span
      id="single-column-label"
      class="ml-3 text-sm leading-4"
      @click="toggle">
      <span class="font-medium text-gray-900">Single column</span>
    </span>
  </div>
</template>
